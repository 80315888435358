body {
  
  padding-bottom:55px;
  height:100%;
  
}
 /*Navigation*/

.Navbar a:hover{
  color:#FFBD59;
  text-decoration: none;
  justify-items: center;
}

.scroll-to-top{
  padding-left: 10px;
}

/*Search*/


/*Modal*/

.close{
  color: #FFBD59;
  height: 20px;
  width: 20px;
}

/*Video Rows*/

.video{
  
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  min-width: 100%;
  height:100%; 
}


.videorow{
  background-color: silver;
  padding-top: 20px;
}

/*Preview Card*/

.paragraph a{
  color: #FFBD59
}

/*Article Cards*/

.cardscroller{
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 10px;
  padding-bottom: 30px;
}

.cardscroller::-webkit-scrollbar {
  display: none;
}

.cardstyle{
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #343a40;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
  min-width: 20rem;
  border-color: #343a40;
}

.cardstyle img {
  width: 100%;
  height: auto;
  padding: 10px; 
}

.cardstyle:hover {
  transform:scale(1.1);
} 

@media only screen and (max-width: 400px){
  .cardstyle{
    text-align: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #343a40;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    max-width: none;
  }
}

@media only screen and (max-width: 400px){
  .cardstyle:hover{
    transform: translate(0,-5px);
    -webkit-transform: translate(0,-5px);
    -o-transform: translate(0,-5px); 
    -moz-transform: translate(0,-5px);
  }
}

.cardsubtitle{
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
}

/*Containers & Wrappers*/

.rowtopper{
  padding-top: 15px;
}
.feedheader{
  text-align: center;
  justify-content: center;
  font-style: italic;
  font-weight: bold;
  color: #FFBD59;
  padding-top: 25px;
  margin-top:-30px;
}

.homecardwrapper{
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
  height:100%;
  padding-top: 50px;
  padding-bottom:30px;
}

.cont{
  width:100%;
}

.contwrapper{
  padding-top:45px;
  background-color:  #5C6D70 ;
}

.img-wrapper {
  position: relative;
 }

.img-responsive {
  width: 100%;
  height: auto;
}

.img-overlay {
  position: absolute;
  float: right;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.img-overlay:before {
  content: ' ';
  display: inline-block;
  /* adjust 'height' to position overlay content vertically */
  height: 60%;
}

/*Top Bar - Tiger*/

.topbar{
  background-color: #343a40;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 999;
}

.bottombar{
  background-color: #343a40;
  color: #FFBD59;
  width: 100%;
  margin-bottom: -60px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 999;
}

/*Jumbotrons*/

.jumbof1esport{
  background-image: url("../assets/images/f1esport.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  height:100px;
}

.jumboteo{
  background-image: url("../assets/images/teo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  height:100px;
}

.jumboesi{
  background-image: url("../assets/images/esi.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 5px;
  height:100px;
}


.jumboestn{
  background-image: url("../assets/images/estn.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  margin-bottom: 5px;
  height:100px;
}

.jumboespn{
  background-image: url("../assets/images/espn_alt.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  height:100px;
}

.jumbogolfcom{
  background-image: url("../assets/images/golfcom.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100px;
}

.jumbogolfchan{
  background-image: url("../assets/images/golfchan.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  height:100px;
  
}

.jumbogolfweek{
  background-image: url("../assets/images/golfweek-logo.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  height:100px;
}

.jumbogolfmonthly{
  background-image: url("../assets/images/golf-monthly.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  height:160px;
  
}
.jumbobunk{
  background-image: url("../assets/images/bunksq.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  height:100px; 
}

.jumbogd{
  background-image: url("../assets/images/gd_logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100px;
}

.jumbopga{
  background-image: url("../assets/images/pga-logo.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100%;
  width:100%; 
}

.jumboautosport{
  background-image: url("../assets/images/autosport.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100%;
  width:100%; 
}

.jumbogoalcom{
  background-image: url("../assets/images/goallogo.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:150px;
  width:100%; 
}

.jumbofb365{
  background-image: url("../assets/images/fb365.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100%;
  width:100%; 
}

.jumboninemin{
  background-image: url("../assets/images/90min.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100%;
  width:100%; 
}

.jumboguard{
  background-image: url("../assets/images/guardian.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100%;
  width:100%; 
}

.jumbomail{
  background-image: url("../assets/images/dailymail.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100%;
  width:100%; 
}

.jumboboxnews{
  background-image: url("../assets/images/boxingnews.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100%;
  width:100%; 
}

.jumboringmag{
  background-image: url("../assets/images/ringmag.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100%;
  width:100%; 
}

.jumbofansided{
  background-image: url("../assets/images/fansided.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 5px;
  margin-top: 15px;
  height:100%;
  width:100%; 
}

.jumbo:hover {
  transform: translate(0,-5px);
  -webkit-transform: translate(0,-5px);
  -o-transform: translate(0,-5px); 
  -moz-transform: translate(0,-5px);
} 


@media only screen and (max-width: 400px){
  .jumbo:hover{
    transform: translate(0,-5px);
    -webkit-transform: translate(0,-5px);
    -o-transform: translate(0,-5px); 
    -moz-transform: translate(0,-5px);
  }
}

.preview{
  text-align: center;
  width: 100%;

}
.preview img {
  width: 100%;
  height: auto; 
}

.headlinecard{
  border: #FFBD59;
  border-radius: 5px;
  border-style: solid;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
}

.headlinecard a:hover{
  color:#FFBD59;
  text-decoration: none;
}
.headlinecard p{
  font-style: italic;
}
.headlinemain{
  display: flex;
  flex-wrap: nowrap;
  padding: 15px;
}

.sharepopover{
  display:inline-flex;
}

.previewpopover{
  width: 20px;
  height: 20px;
  display: inline-block;
}





